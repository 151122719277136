import React from 'react'

import styles from './styles.module.css'
import Titulo from '../../Components/Titulo'

import image from '../../assets/img/img-home.jpg'
import home from '../../assets/img/banners/HOMEPAGE.png'

export default function(){
    return (
        <>
            <div className={styles.banner}>
                <img className="img-fluid" src={home} alt="MINASPISO" />
            </div>
            <Titulo>A MINASPISO é uma empresa especializada em aplicação de concreto para pisos industriais e de alta resistência</Titulo>
            <div className={"row justify-content-center text-center " + styles.titleHome}>
                <div className="col-10">
                    <h2>Por que escolher a MINASPSO?</h2><hr/>
                </div>
            </div>
            <div className={" row justify-content-center text-center " + styles.containerDiferenciais}>
                <div className="col-12 col-md-3">
                    <h5>Segurança e tranquilidade</h5>
                    <p>Contratando a MINASPISO para executar o seu piso polido, você não terá problemas com fiscais do Ministério do Trabalho</p>
                </div>

                <div className="col-12 col-md-3">
                    <h5>Colaboradores capacitados</h5>
                    <p>Nossos colaboradores são registrados, segurados e capacitados.</p>
                </div>

                <div className="col-12 col-md-3">
                    <h5>Trabalho do seu jeito</h5>
                    <p>Para seu piso polido, você diz o que quer, que a MINASPISO executa.</p>
                </div>
            </div>
            <div className="row">
                <div className={ "col-10 m-auto " + styles.textHome }>
                    <p>
                        A MINASPISO é uma empresa especializada em aplicação de concreto para pisos industriais e de alta resistência.
                    </p>

                    <p>
                        Há vários anos no mercado, a MINASPISO é uma empresa conceituada no segmento pavimentação de concreto, polimento de pisos industriais, terreiros de café e seus derivativos.
                    </p>

                    <p>
                        Com o compromisso de levar aos seus clientes o melhor e o mais qualificado trabalho, nossa equipe é especializada e treinada para a aplicação de produtos com a maior tecnologia voltada para o segmento de pavimentação de concreto.
                    </p>

                    <img className={"img-fluid " + styles.imageHome}  src={image} alt="Obra Porto Seco executada em 2017" />
                    <small className="d-block text-center">Obra Porto Seco executada em 2017</small>
                </div>
            </div>
        </>
    )
}