import React, { useState } from 'react'
import logo from '../../assets/logo.svg'
import { FaFacebook, FaInstagram, FaWhatsapp, FaYoutube } from 'react-icons/fa'
import styles from './styles.module.css'
import { Navbar, NavbarToggler, Collapse, Nav, NavItem, NavLink } from 'reactstrap'

import { Link } from 'react-router-dom'

export default function(){
    const [navbarOpen, setNavbarOpen] = useState(false)

    const toggleNavbar = () => setNavbarOpen(!navbarOpen)

    return (
        <header>
            <div className="container-fluid">
                <div className="row p-3 pt-5">
                    <div className="col-xs-12 col-sm-6 col-md-9 mr-auto text-center text-sm-left">
                        <img className={"img-fluid " + styles.logo} src={logo} alt="MINASPISO LTDA" />
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md ml-auto text-center pt-4 pt-sm-0">
                        <p>
                            <FaFacebook  onClick={() => window.open("http://www.facebook.com/MinaspisoCongonhalLtda", "_blank")}  size={'2em'} color="blue" />&nbsp;&nbsp;&nbsp;
                            <FaInstagram onClick={() => window.open("http://www.instagram.com/minaspiso_ltda", "_target")}  size={'2em'} color="#00A650" />&nbsp;&nbsp;&nbsp;
                            <FaWhatsapp  onClick={() => window.open("https://api.whatsapp.com/send?phone=5535999873559&text=&source=&data=", "_blank")} size={'2em'} color="#00A650" />&nbsp;&nbsp;&nbsp;
                            <FaYoutube   onClick={() => window.open("https://www.youtube.com/channel/UCRm1LW7du_YAAIAJy-dWUPQ", "_blank")} size={'2em'} color="red" />&nbsp;&nbsp;&nbsp;
                        </p>
                        <p className={styles.phone}>
                            (35) 99987-3559<br />
                            (35) 99941-1188
                        </p>
                    </div>
                </div>
            </div>
            <div className={styles.bgNavbar}>
                <Navbar light expand="md">
                    <NavbarToggler onClick={toggleNavbar} />
                    
                    <Collapse isOpen={navbarOpen} navbar>
                        <Nav className="m-auto" navbar>
                            <NavItem>
                                <NavLink><Link className={styles.navlink} to="/">INÍCIO</Link></NavLink>
                                {/* <NavLink className={styles.navlink} href="/">INÍCIO</NavLink> */}
                            </NavItem>
                            <NavItem>
                                <NavLink><Link className={styles.navlink} to="/sobre">SOBRE</Link></NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink><Link className={styles.navlink} to="/servicos">SERVIÇOS</Link></NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink><Link className={styles.navlink} to="/parceiros">PARCEIROS</Link></NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink><Link className={styles.navlink} to="/obras">OBRAS</Link></NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink><Link className={styles.navlink} to="/contato">CONTATO</Link></NavLink>
                            </NavItem>
                        </Nav>
                    </Collapse>
                </Navbar>
            </div>
        </header>
    )
}